import React from "react";

import Head from "next/head";
import { useRouter } from "next/router";

import { Breadcrumbs } from "@/components/breadcrumbs";
import { Column, Grid, Row } from "@/components/grid";
import Layout from "@/components/layout";
import { Spacer } from "@/components/layout/components";
import { Slot } from "@/components/slot";
import { Typography } from "@/components/typography/typography";
import { Visual } from "@/components/visual";
import dirs from "@/contentful/content-model/dirs";
import type { Locale } from "@/contentful/content-model/types";
import { Dirs } from "@/contentful/content-model/types";
import { BreadcrumbsProvider } from "@/context/breadcrumbs";
import { TypographyVariant } from "@/theme";
import type {
	BlogPost,
	BusinessInfoCollection,
	NavigationCollection,
	Person,
	SubPage,
	TextCollection,
} from "@/types/contentful-api";

import type { PageTemplateProps } from "./types";

export const Template: React.FC<
	PageTemplateProps<{
		page: SubPage;
		entryListCollection: Record<string, SubPage[] | BlogPost[] | Person[]>;
		textCollection: TextCollection;
		headerNavigation: NavigationCollection;
		footerNavigation: NavigationCollection;
		businessInfoCollection: BusinessInfoCollection;
	}>
> = ({ data, url }) => {
	const { locale } = useRouter();

	const { page, entryListCollection } = data;
	const {
		sys: { publishedAt },
		meta,
		dir: subPageDir,
		slug: subPageSlug,
		breadcrumb: subPageBreadcrumb,
		keyVisual,
		headline,
		slotsCollection: { items: slots },
	} = page;

	// @todo: `breadcrumbs` generation should be moved
	//   to getStaticProps of corresponding `SubPageTemplate` users
	const breadcrumbs =
		subPageDir === Dirs.press
			? [
					{
						title: dirs[Dirs.ROOT].breadcrumb[locale],
						href: "/",
					},
					{
						title: dirs[Dirs[subPageDir]].breadcrumb[locale],
						href: `/${dirs[Dirs[subPageDir]].dir[locale] as string}/`,
					},
					{
						title: subPageBreadcrumb,
						href: `/${dirs[Dirs[subPageDir]].dir[locale] as string}/${subPageSlug}/`,
					},
			  ]
			: [];

	return (
		<Layout translucentHeader data={data} meta={meta} url={url}>
			<Head>
				<meta name="last-publish" content={publishedAt} />
			</Head>
			<BreadcrumbsProvider value={breadcrumbs}>
				{keyVisual && <Visual data={keyVisual} textCollection={data.textCollection} />}
				{headline && !keyVisual && (
					<Grid>
						<Row>
							<Column>
								<Typography
									component="h1"
									variant={TypographyVariant.headlineSerifLG}
								>
									{headline}
								</Typography>
							</Column>
						</Row>
					</Grid>
				)}
				{page.dir === Dirs.press && (
					<Grid>
						<Row>
							<Column>
								<Breadcrumbs />
							</Column>
						</Row>
					</Grid>
				)}
				{slots.map(slot => (
					<Slot
						key={slot.sys.id}
						locale={locale as Locale}
						// @todo: rename `data` prop to something more specific
						data={entryListCollection[`query_${slot.sys.id}`]}
						textCollection={data.textCollection}
						{...slot}
					/>
				))}
				<Spacer spacing="xxl" />
			</BreadcrumbsProvider>
		</Layout>
	);
};
